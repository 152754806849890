<template>
    <div>
        <div
            v-if="asset.asset_details.asset_type_type === 'comtac-td1'"
            class="l-padded l-inline l-gap-1"
        >
            <base-multiselect
                v-model="positioningMethod"
                :options="positioningMethodOptions"
            />

            <base-button
                :disabled="!positioningMethod"
                size="small"
                @click="handlePositionGet"
            >
                {{ $t('getPosition') }}
            </base-button>
        </div>

        <measurement-item v-for="(item, key) in configData" :key="key">
            <template #icon>
                <cog-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t(key) }}:

                <h3 :class="item.class">
                    {{ item.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatHelper, httpHelper } from '@/utils'
import BaseButton from './redesigned/BaseButton'
import BaseMultiselect from './redesigned/BaseMultiselect'
import CogIcon from './icons/CogIcon'
import MeasurementItem from './MeasurementItem'

export default {
    name: 'AssetConfigList',
    components: {
        BaseButton,
        BaseMultiselect,
        CogIcon,
        MeasurementItem,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            positioningMethod: null,
            positioningMethodOptions: [
                'gps-only',
                'wifi-only',
                'gps-or-wifi',
                'wifi-or-gps',
                'gps-and-wifi',
            ],
        }
    },
    computed: {
        ...mapGetters('authentication', ['isAdminOrStaff', 'isStaff']),
        configData() {
            return {
                ...(this.asset.config?.software_version && {
                    softwareVersion: {
                        value: this.asset.config.software_version,
                    },
                }),
                ...(this.asset.config?.serial_number && {
                    serialNumber: {
                        value: this.asset.config.serial_number,
                    },
                }),
                ...(this.asset.config?.interval && {
                    interval: {
                        value: formatHelper.hoursAndMinutesDuration(
                            this.asset.config.interval * 60
                        ),
                    },
                }),
                ...(this.asset.config?.auto_positioning_interval && {
                    autoPositioningInterval: {
                        value: formatHelper.hoursAndMinutesDuration(
                            this.asset.config.auto_positioning_interval
                        ),
                    },
                }),
                ...(this.asset.config?.default_positioning_method && {
                    defaultPositioningMethod: {
                        value: this.asset.config.default_positioning_method,
                    },
                }),
                ...(this.asset.config?.gps_fixes && {
                    gpsFixes: {
                        value: this.asset.config.gps_fixes,
                    },
                }),
                ...(this.asset.config?.long_range_trigger && {
                    longRangeTrigger: {
                        value: this.asset.config.long_range_trigger,
                    },
                }),
                ...(this.asset.config?.mid_range_trigger && {
                    midRangeTrigger: {
                        value: this.asset.config.mid_range_trigger,
                    },
                }),
                ...(this.asset.config?.min_wifi_detections && {
                    minWifiDetections: {
                        value: this.asset.config.min_wifi_detections,
                    },
                }),
                ...(this.asset.config?.ping_interval && {
                    pingInterval: {
                        value: formatHelper.hoursAndMinutesDuration(
                            this.asset.config.ping_interval * 60
                        ),
                    },
                }),
                ...(this.asset.config?.rejoin_trigger && {
                    rejoinTrigger: {
                        value: this.asset.config.rejoin_trigger,
                    },
                }),
                ...(this.isAdminOrStaff && {
                    ...(this.asset.config?.probe_address && {
                        probeAddress: {
                            value: this.asset.config.probe_address,
                        },
                    }),
                }),
                ...(this.isStaff && {
                    ...(this.asset.config?.gps_accuracy_goal && {
                        gpsAccuracyGoal: {
                            value: this.asset.config.gps_accuracy_goal,
                            class: 'sensor-data-distance-cm',
                        },
                    }),
                    ...(this.asset.config?.gps_fix_interval && {
                        gpsFixInterval: {
                            value: this.asset.config.gps_fix_interval,
                            class: 'sensor-data-time-s',
                        },
                    }),
                    ...(this.asset.config?.gps_fix_time && {
                        gpsFixTime: {
                            value: this.asset.config.gps_fix_time,
                            class: 'sensor-data-time-s',
                        },
                    }),
                    ...(this.asset.config?.firmware_version && {
                        firmwareVersion: {
                            value: this.asset.config.firmware_version,
                        },
                    }),
                }),
            }
        },
    },
    watch: {
        asset: {
            immediate: true,
            handler() {
                this.positioningMethod = this.asset.config?.default_positioning_method
            },
        },
    },
    methods: {
        async handlePositionGet() {
            await httpHelper.post(
                `lora/downlinks/comtac-td1/get-position/`,
                null,
                {
                    params: {
                        deveui: this.asset.deveui,
                        positioning_method: this.positioningMethod,
                    },
                }
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "autoPositioningInterval": "Auto Positioning Interval",
        "defaultPositioningMethod": "Default Positioning Method",
        "firmwareVersion": "Firmware Version",
        "getPosition": "Get",
        "gpsAccuracyGoal": "GPS Accuracy Goal",
        "gpsFixInterval": "GPS Fix Interval",
        "gpsFixTime": "GPS Fix Time",
        "gpsFixes": "GPS Fixes",
        "interval": "Interval",
        "longRangeTrigger": "Long Range Trigger",
        "midRangeTrigger": "Mid Range Trigger",
        "minWifiDetections": "Min WiFi Detections",
        "pingInterval": "Ping Interval",
        "probeAddress": "Probe Address",
        "rejoinTrigger": "Rejoin Trigger",
        "serialNumber": "Serial Number",
        "softwareVersion": "Software Version"
    },
    "de": {
        "autoPositioningInterval": "Auto Positioning Interval",
        "defaultPositioningMethod": "Default Positioning Method",
        "firmwareVersion": "Firmware Version",
        "getPosition": "Get",
        "gpsAccuracyGoal": "GPS Genauigkeit",
        "gpsFixInterval": "GPS Fix Intervall",
        "gpsFixTime": "GPS Fix Zeit",
        "gpsFixes": "GPS Fixes",
        "interval": "Messintervall",
        "longRangeTrigger": "Long Range Trigger",
        "midRangeTrigger": "Mid Range Trigger",
        "minWifiDetections": "Min WiFi Detections",
        "pingInterval": "Ping Interval",
        "probeAddress": "Sondenadresse",
        "rejoinTrigger": "Rejoin Trigger",
        "serialNumber": "Serial Number",
        "softwareVersion": "Software Version"
    },
    "it": {
        "autoPositioningInterval": "Auto Positioning Interval",
        "defaultPositioningMethod": "Default Positioning Method",
        "firmwareVersion": "Versione del firmware",
        "getPosition": "Get",
        "gpsAccuracyGoal": "Precisione GPS",
        "gpsFixInterval": "Intervallo di GPS",
        "gpsFixTime": "Tempo di GPS",
        "gpsFixes": "GPS Fixes",
        "interval": "Intervallo",
        "longRangeTrigger": "Long Range Trigger",
        "midRangeTrigger": "Mid Range Trigger",
        "minWifiDetections": "Min WiFi Detections",
        "pingInterval": "Ping Interval",
        "probeAddress": "Indirizzo della sonda",
        "rejoinTrigger": "Rejoin Trigger",
        "serialNumber": "Serial Number",
        "softwareVersion": "Software Version"
    }
}
</i18n>
