<template>
    <div>
        <div class="l-stack  u-bb">
            <div class="table-parent">
                <div class="l-inline l-justify-end l-center-v l-gap-0">
                    <div>{{ $t('temperature') }}</div>
                    <thermometer-icon />
                </div>
                <div class="l-inline l-center-v l-gap-0">
                    <drop-icon />
                    <div>{{ $t('moisture') }}</div>
                </div>
                <template v-for="(data, index) in depthData">
                    <div :key="index + '-temp'" class="l-stack l-spread">
                        <div class="l-inline l-spread l-full-width">
                            <div v-if="index === 0" class="t-subtle t-small">
                                {{ $t('top') }}
                            </div>
                            <div
                                v-else-if="index === depthData.length - 1"
                                class="t-subtle t-small"
                            >
                                {{ $t('bottom') }}
                            </div>
                            <div v-else></div>

                            <div>{{ data.temperature.toFixed(1) }} °C</div>
                        </div>
                        <div
                            :style="temperatureGraphStyle(data, index)"
                            class="graph-bar"
                        ></div>
                    </div>
                    <div :key="index + '-moist'" class="l-stack l-spread">
                        <div>{{ data.moisture.toFixed(1) }}</div>
                        <div
                            :style="moistureGraphStyle(data, index)"
                            class="graph-bar"
                        ></div>
                    </div>
                </template>
            </div>
            <div class="l-inline l-center l-gap-2 l-grow-1">
                <router-link
                    :to="{ name: 'treehistorytemperaturechart' }"
                    class="l-grow-1"
                >
                    <list-item>
                        <div class="l-inline l-gap-2 l-grow-1 l-center-v">
                            <bar-chart-icon></bar-chart-icon>

                            <div>
                                {{ $t('showhistorytemperature') }}
                            </div>
                        </div>
                        <arrow-right-icon></arrow-right-icon>
                    </list-item>
                </router-link>
            </div>
            <div class="l-inline l-center l-gap-2 l-grow-1">
                <router-link
                    :to="{ name: 'treehistorymoisturechart' }"
                    class="l-grow-1"
                >
                    <list-item>
                        <div class="l-inline l-gap-2 l-grow-1 l-center-v">
                            <bar-chart-icon></bar-chart-icon>

                            <div>
                                {{ $t('showhistorymoisture') }}
                            </div>
                        </div>
                        <arrow-right-icon></arrow-right-icon>
                    </list-item>
                </router-link>
            </div>
        </div>

        <measurement-item
            v-if="moistureAverage"
            :title="moistureAverage.title"
            :datetime="moistureAverage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: moistureAverage.key },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('moistureAverage') }}:
                <h3>
                    {{ moistureAverage.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperatureAverage"
            :title="temperatureAverage.title"
            :datetime="temperatureAverage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: temperatureAverage.key },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperatureAverage') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperatureAverage.value }}
                </h3>
            </template>
        </measurement-item>

        <battery-measurements-fragment :tracker="tracker" />
    </div>
</template>

<script>
import ArrowRightIcon from './icons/ArrowRightIcon'
import BarChartIcon from './icons/BarChartIcon'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import DropIcon from './icons/DropIcon'
import ListItem from './ListItem'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

const { VUE_APP_COLOR_ACCENT: colorAccent } = process.env

export default {
    name: 'MoistureMeasurements',
    components: {
        ArrowRightIcon,
        BarChartIcon,
        BatteryMeasurementsFragment,
        DropIcon,
        ListItem,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        moistureAverage() {
            return this.getMeasurementItem('moisture')
        },
        temperatureAverage() {
            return this.getMeasurementItem('temperature')
        },
        temperatures() {
            if (this.tracker?.asset_details?.sensor_data) {
                return Object.fromEntries(
                    Object.entries(this.tracker.asset_details.sensor_data)
                        .filter(([key]) => key.indexOf('temperature_') === 0)
                        .map(([key, value]) => {
                            key = key.replace('temperature_', '')
                            return [key, value]
                        })
                )
            }
            return {}
        },
        moistures() {
            if (this.tracker?.asset_details?.sensor_data) {
                return Object.fromEntries(
                    Object.entries(this.tracker.asset_details.sensor_data)
                        .filter(([key]) => key.indexOf('moisture_') === 0)
                        .map(([key, value]) => {
                            key = key.replace('moisture_', '')
                            return [key, value]
                        })
                )
            }
            return {}
        },
        depthData() {
            let data = []
            for (const [key, temp] of Object.entries(this.temperatures)) {
                let moisture = this.moistures[key]
                data.push({
                    temperature: temp.value,
                    moisture: moisture.value,
                    level: Number(key) + 1,
                })
            }
            return data
        },
    },
    methods: {
        temperatureGraphPercent(value) {
            const min = -10
            const max = 40

            const normalizedMax = max - min
            const normalizedValue = value - min
            return (normalizedValue * 100) / normalizedMax
        },
        moistureGraphPercent(value) {
            const min = 0
            const max = 100

            const normalizedMax = max - min
            const normalizedValue = value - min
            return (normalizedValue * 100) / normalizedMax
        },
        moistureGraphStyle(data) {
            return {
                background:
                    'linear-gradient(90deg, #0077c2 ' +
                    this.moistureGraphPercent(data.moisture) +
                    '%, rgba(0,0,0,0.08) ' +
                    this.moistureGraphPercent(data.moisture) +
                    '%)',
            }
        },
        temperatureGraphStyle(data) {
            let val = 100 - this.temperatureGraphPercent(data.temperature)
            return {
                background:
                    'linear-gradient(90deg, rgba(0,0,0,0.08) ' +
                    val +
                    '%, ' +
                    colorAccent +
                    ' ' +
                    val +
                    '%)',
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "temperatureAverage": "⌀ Temperature",
        "temperature": "Temperature",
        "moistureAverage": "⌀ Moisture",
        "moisture": "Moisture",
        "top": "Top",
        "bottom": "Bottom",
        "showhistorytemperature": "Show historic temperature",
        "showhistorymoisture": "Show historic moisture"
    },
    "de": {
        "temperatureAverage": "⌀ Temperatur",
        "temperature": "Temperatur",
        "moistureAverage": "⌀ Feuchtigkeit",
        "moisture": "Feuchtigkeit",
        "top": "Oben",
        "bottom": "Unten",
        "showhistorytemperature": "Historische Temperatur anzeigen",
        "showhistorymoisture": "Historische Feuchtigkeit anzeigen"
    },
    "it": {
        "temperatureAverage": "⌀ Temperatura",
        "temperature": "Temperatura",
        "moistureAverage": "⌀ Umidità",
        "moisture": "Umidità",
        "top": "Sopra",
        "bottom": "Sotto",
        "showhistorytemperature": "Mostra la temperatura storica",
        "showhistorymoisture": "Mostra l'umidità storica"
    }
}
</i18n>

<style scoped lang="scss">
.table-parent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px 10px;
    padding: 16px;
}

.graph-bar {
    height: 4px;
    width: 100%;
}
</style>
