<template>
    <div>
        <measurement-item
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: battery.key },
            }"
        >
            <template #icon>
                <battery-icon
                    :battery-level-in-percent="battery.value"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="voltage"
            :title="voltage.title"
            :datetime="voltage.lastUpdated"
            :link-to="{
                name: 'chartsCombined',
                params: {
                    id: tracker.id,
                    dataTypes: voltage.key,
                    simulated: isSimulated,
                },
            }"
        >
            <template #icon>
                <flash-icon width="24" height="24" />
            </template>

            <template #content>
                <div class="measurement-value-range">
                    <div
                        v-for="(measurement, i) in voltage.key"
                        :key="i"
                        class="l-inline l-center-v l-spread"
                        :class="{ highlighted: voltage.value[i] < 3 }"
                    >
                        <span class="t-small">
                            {{ $t(measurement) }}
                        </span>

                        <strong class="sensor-data-voltage-k">
                            {{ voltage.value[i] }}
                        </strong>
                    </div>
                </div>
            </template>
        </measurement-item>

        <measurement-item
            v-if="voltage && status"
            :title="status.title"
            :datetime="status.lastUpdated"
            :link-to="{
                name: 'fenceguardstatuschart',
                params: { id: tracker.id, simulated: isSimulated },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="status.value ? 'green' : 'red'"
                />
            </template>

            <template #content>
                <h3 class="measurement-value">
                    {{ status.value ? $t('ok') : $t('okNot') }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import BatteryIcon from './icons/BatteryIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import FlashIcon from './icons/FlashIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'FenceGuardMeasurement',
    components: {
        BatteryIcon,
        FilledCircleIcon,
        FlashIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        isSimulated() {
            return (
                this.tracker.asset_details.asset_type_type ===
                'fence-guard-simulated'
            )
        },
        battery() {
            return this.getMeasurementItem('battery')
        },
        status() {
            if (!this.voltage) {
                return null
            }

            return {
                key: 'fence-guard-status',
                title: this.voltage.title,
                value: this.voltage.value.every(item => Number(item) >= 3),
                lastUpdated: this.voltage.lastUpdated,
            }
        },
        voltage() {
            const keys = [
                'voltage_0',
                'voltage_1',
                'voltage_2',
                'voltage_3',
                'voltage_4',
            ]

            const data = keys
                .map(key => this.tracker.asset_details.sensor_data[key])
                .filter(Boolean)

            if (!data.length) {
                return null
            }

            const lastUpdated = data[0].last_update

            return {
                key: keys,
                title:
                    lastUpdated &&
                    this.getItemTitle(keys.join(' | '), lastUpdated),
                value: data.map(data => (data.value / 1000).toFixed(2)),
                lastUpdated,
            }
        },
        voltage0() {
            const data = this.getMeasurementItem('voltage_0')
            data.value = (data.value / 1000).toFixed(2)
            return data
        },
        voltage1() {
            const data = this.getMeasurementItem('voltage_1')
            data.value = (data.value / 1000).toFixed(2)
            return data
        },
        voltage2() {
            const data = this.getMeasurementItem('voltage_2')
            data.value = (data.value / 1000).toFixed(2)
            return data
        },
        voltage3() {
            const data = this.getMeasurementItem('voltage_3')
            data.value = (data.value / 1000).toFixed(2)
            return data
        },
        voltage4() {
            const data = this.getMeasurementItem('voltage_4')
            data.value = (data.value / 1000).toFixed(2)
            return data
        },
    },
}
</script>

<i18n>
{
    "en": {
        "battery": "Battery",
        "ok": "OK",
        "okNot": "Not OK",
        "status": "Status",
        "voltage_0": "Wire 1",
        "voltage_1": "Wire 2",
        "voltage_2": "Wire 3",
        "voltage_3": "Wire 4",
        "voltage_4": "Wire 5"
    },
    "de": {
        "battery": "Batterie",
        "ok": "Spannungen OK",
        "okNot": "Spannungen nicht OK",
        "status": "Status",
        "voltage_0": "Litze 1",
        "voltage_1": "Litze 2",
        "voltage_2": "Litze 3",
        "voltage_3": "Litze 4",
        "voltage_4": "Litze 5"
    },
    "it": {
        "battery": "Batteria",
        "ok": "OK",
        "okNot": "Not OK",
        "status": "Status",
        "voltage_0": "Filo 1",
        "voltage_1": "Filo 2",
        "voltage_2": "Filo 3",
        "voltage_3": "Filo 4",
        "voltage_4": "Filo 5"
    }
}
</i18n>

<style lang="scss" scoped>
.measurement-value {
    padding-bottom: 2px;
    padding-left: 0;
    font-size: 20px;
}

.measurement-value-range {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
    padding-bottom: 4px;
    width: 100%;
    align-items: flex-start;
    padding-left: 0;

    & > * {
        padding: 2px 0;
        width: 100%;
        border-bottom: 2px solid $color-gray-dark-new;
        line-height: 20px;

        &.highlighted {
            border-bottom: 2px solid $color-danger;
        }
    }

    h3 {
        display: inline;
    }
}

.green {
    color: $color-green;
}

.red {
    color: $color-red;
}
</style>
