<template>
    <div>
        <component
            :is="customMeasurementComponent"
            v-if="customMeasurementComponent"
            :tracker="tracker"
        />

        <template v-else>
            <measurement-item
                v-if="humidity"
                :title="humidity.title"
                :datetime="humidity.lastUpdated"
                :link-to="
                    temperature
                        ? {
                              name: 'chartsCombined',
                              params: {
                                  id: tracker.id,
                                  dataTypes: [humidity.key, temperature.key],
                              },
                          }
                        : {
                              name: 'charts',
                              params: {
                                  id: tracker.id,
                                  dataType: humidity.key,
                              },
                          }
                "
            >
                <template #icon>
                    <DropIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('humidity') }}:

                    <h3 class="sensor-data-percentage">
                        {{ humidity.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="temperature"
                :title="temperature.title"
                :datetime="temperature.lastUpdated"
                :link-to="
                    humidity
                        ? {
                              name: 'chartsCombined',
                              params: {
                                  id: tracker.id,
                                  dataTypes: [humidity.key, temperature.key],
                              },
                          }
                        : {
                              name: 'charts',
                              params: {
                                  id: tracker.id,
                                  dataType: temperature.key,
                              },
                          }
                "
            >
                <template #icon>
                    <ThermometerIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('temperature') }}:

                    <h3 class="sensor-data-temperature">
                        {{ temperature.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="distance"
                :title="distance.title"
                :datetime="distance.lastUpdated"
                :link-to="{
                    name: 'charts',
                    params: { id: tracker.id, dataType: distance.key },
                }"
            >
                <template #icon>
                    <DistanceIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('distance') }}:

                    <h3 class="sensor-data-distance">
                        {{ distance.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="speed"
                :title="speed.title"
                :datetime="speed.lastUpdated"
                :link-to="{
                    name: 'charts',
                    params: {
                        id: tracker.id,
                        dataType: speed.key,
                    },
                }"
            >
                <template #icon>
                    <SpeedIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('speed') }}:

                    <h3 class="sensor-data-speed">
                        {{ speed.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="co2"
                :title="co2.title"
                :datetime="co2.lastUpdated"
                :link-to="{
                    name: 'charts',
                    params: { id: tracker.id, dataType: co2.key },
                }"
            >
                <template #icon>
                    <CO2Icon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('co2') }}:

                    <h3 class="sensor-data-co2">
                        {{ co2.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="voc"
                :title="voc.title"
                :datetime="voc.lastUpdated"
                :link-to="{
                    name: 'charts',
                    params: { id: tracker.id, dataType: voc.key },
                }"
            >
                <template #icon>
                    <VocIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('voc') }}:

                    <h3 class="sensor-data-voc">
                        {{ voc.value }}
                    </h3>
                </template>
            </measurement-item>

            <battery-measurements-fragment :tracker="tracker" />

            <measurement-item
                v-if="hasRunningTimeAccess && runningTime"
                :datetime="runningTime.lastUpdated"
                :link-to="{
                    name: 'runningTimeChart',
                    params: { id: tracker.id },
                }"
            >
                <template #icon>
                    <stopwatch-icon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('runningTime') }}:

                    <h3>
                        {{ runningTime.textDuration }}
                    </h3>
                </template>
            </measurement-item>
        </template>

        <measurement-item
            v-if="driverName"
            :title="driverName.title"
            :datetime="driverName.lastUpdated"
        >
            <template #icon>
                <person-icon width="24" height="24" />
            </template>

            <template #content>
                <h3 class="no-padding">{{ driverName.value }}</h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatHelper } from '../utils'
import AlertMeasurement from './AlertMeasurement'
import AnimalMeasurement from './asset-types/animal/AnimalMeasurement'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import BinMeasurement from './BinMeasurement'
import CM2HumidityMeasurement from './CM2HumidityMeasurement'
import CO2Icon from './icons/CO2Icon'
import DigitalInputMeasurement from './DigitalInputMeasurement'
import DistanceIcon from './icons/DistanceIcon'
import DropIcon from './icons/DropIcon'
import DynamicBinCollectionMeasurement from './DynamicBinCollectionMeasurement'
import DynamicBinMeasurement from './asset-types/dynamic-bin/DynamicBinMeasurement'
import EcocoachPowerTrolleyMeasurement from './EcocoachPowerTrolleyMeasurement'
import FenceGuardMeasurement from './FenceGuardMeasurement'
import FlatRoofLightMeasurement from './FlatRoofLightMeasurement'
import FlatRoofMeasurement from './FlatRoofMeasurement'
import HeatingSystemMeasurement from './HeatingSystemMeasurement'
import LevelMeasurement from './LevelMeasurement'
import MachineMeasurement from './MachineMeasurement'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import MoistureMeasurements from './MoistureMeasurements'
import ParkingSpaceMeasurement from './ParkingSpaceMeasurement'
import PersonIcon from './icons/PersonIcon'
import SbbBinMeasurement from './asset-types/sbb-bin/SbbBinMeasurement'
import SiloMeasurement from './SiloMeasurement'
import SolarPowerMeasurement from './SolarPowerMeasurement'
import SpeedIcon from './icons/SpeedIcon'
import StopwatchIcon from './icons/RefreshArrowsIcon'
import TeltonikaRouterMeasurement from './TeltonikaRouterMeasurement'
import ThermometerIcon from './icons/ThermometerIcon'
import VocIcon from './icons/VocIcon'

const customMeasurementComponents = {
    'cm2-humidity': CM2HumidityMeasurement,
    'digital-input': DigitalInputMeasurement,
    'dynamic-bin': DynamicBinMeasurement,
    'dynamic-bin-collection': DynamicBinCollectionMeasurement,
    'ecocoach-power-trolley': EcocoachPowerTrolleyMeasurement,
    'fence-guard': FenceGuardMeasurement,
    'fence-guard-simulated': FenceGuardMeasurement,
    'flat-roof': FlatRoofMeasurement,
    'flat-roof-light': FlatRoofLightMeasurement,
    'flat-roof-probe': FlatRoofMeasurement,
    'heating-system': HeatingSystemMeasurement,
    'parking-space': ParkingSpaceMeasurement,
    'sbb-bin': SbbBinMeasurement,
    alert: AlertMeasurement,
    animal: AnimalMeasurement,
    bin: BinMeasurement,
    level: LevelMeasurement,
    machine: MachineMeasurement,
    moisture: MoistureMeasurements,
    power: SolarPowerMeasurement,
    silo: SiloMeasurement,
    teltonika_rtu240: TeltonikaRouterMeasurement,
}

export default {
    name: 'MeasurementsList',
    components: {
        BatteryMeasurementsFragment,
        CO2Icon,
        DistanceIcon,
        DropIcon,
        MeasurementItem,
        PersonIcon,
        SpeedIcon,
        StopwatchIcon,
        ThermometerIcon,
        VocIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        ...mapGetters('authentication', ['hasRunningTimeAccess']),
        co2() {
            return this.getMeasurementItem('co2')
        },
        customMeasurementComponent() {
            return customMeasurementComponents[
                this.tracker.asset_details.asset_type_type
            ]
        },
        distance() {
            return this.getMeasurementItem('distance')
        },
        driverName() {
            return this.getMeasurementItem('driver_name')
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        speed() {
            const item = this.getMeasurementItem('speed')
            if (item) {
                item.value = Math.round(item.value * 3.6)
            }
            return item
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        voc() {
            return this.getMeasurementItem('voc')
        },
        runningTime() {
            const item = this.getMeasurementItem('running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item
        },
    },
}
</script>

<i18n>
{
    "en": {
        "co2": "Carbon dioxide",
        "distance": "Distance",
        "humidity": "Humidity",
        "runningTime": "Operating hours",
        "speed": "Speed",
        "temperature": "Temperature",
        "voc": "VOC"
    },
    "de": {
        "co2": "Kohlenstoffdioxid",
        "distance": "Distanz",
        "humidity": "Luftfeuchtigkeit",
        "runningTime": "Betriebsstunden",
        "speed": "Speed",
        "temperature": "Temperatur",
        "voc": "VOC"
    },
    "it": {
        "co2": "Anidride cabonica",
        "distance": "Distanza",
        "humidity": "Umidità",
        "runningTime": "Ore di funzionamento",
        "speed": "Speed",
        "temperature": "Temperatura",
        "voc": "VOC"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}

.no-padding {
    padding: 0;
}
</style>
