<template>
    <div>
        <measurement-item
            v-if="fullness"
            :title="fullness.title"
            :datetime="fullness.lastUpdated"
            :link-to="{
                name: 'sbbbinchart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <bin-fill-icon
                    width="24"
                    height="24"
                    :fullness="fullness.value"
                    :fill-color="colorsMap[fullness.level]"
                />
            </template>

            <template #content>
                {{ $t('fullness') }}:

                <h3 class="sensor-data-percentage">
                    {{ fullness.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="lastCollected"
            :title="lastCollected.title"
            :datetime="lastCollected.lastUpdated"
            :link-to="{
                name: 'sbbbinchart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <trash-bin-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('lastCollected') }}:

                <h3 v-if="lastCollected.lastUpdated">
                    <TimeAgo :from-datetime="lastCollected.lastUpdated" />
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: temperature.key },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <battery-measurements-fragment :tracker="tracker" />
    </div>
</template>

<script>
import { measurementHelper } from '@/utils'

import BatteryMeasurementsFragment from '../../BatteryMeasurementsFragment'
import BinFillIcon from '../../icons/BinFillIcon'
import MeasurementItem from '../../MeasurementItem'
import MeasurementListHelper from '../../../mixins/MeasurementListHelper'
import ThermometerIcon from '../../icons/ThermometerIcon'
import TimeAgo from '../../TimeAgo'
import TrashBinIcon from '../../icons/BinIcon'

export default {
    name: 'SbbBinMeasurement',
    components: {
        BatteryMeasurementsFragment,
        BinFillIcon,
        MeasurementItem,
        ThermometerIcon,
        TimeAgo,
        TrashBinIcon,
    },
    mixins: [MeasurementListHelper],
    data() {
        return {
            colorsMap: {
                low: '#41b883',
                medium: '#fdae61',
                high: '#d32f2f',
            },
        }
    },
    computed: {
        fullness() {
            const measurement = 'distance'
            const data = this.getMeasurementItem(measurement)

            if (!data) {
                return null
            }

            const value = measurementHelper.convertToSbbBinLevel(
                this.tracker.asset_details.sensor_data[measurement].value
            )

            const [low, height] =
                this.tracker.asset_details.asset_type_identifier ===
                'sbb-bin-paper'
                    ? [20, 35]
                    : [40, 75]

            const level =
                value <= low ? 'low' : value >= height ? 'high' : 'medium'

            return { ...data, value, level }
        },
        lastCollected() {
            return this.getMeasurementItem('accel_y')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "fullness": "Fill level",
            "lastCollected": "Last collection",
            "temperature": "Temperature"
        },
        "de": {
            "fullness": "Füllstand",
            "lastCollected": "Letzte Leerung",
            "temperature": "Temperatur"
        },
        "it": {
            "fullness": "Livello di riempimento",
            "lastCollected": "Ultimo svuotamento",
            "temperature": "Temperatura"
        }
    }
</i18n>

<style lang="scss" scoped>
h3 {
    p {
        font-size: unset;
    }
}

p {
    font-size: $fs-small;
}
</style>
