var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.temperature)?_c('measurement-item',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.temperature.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('thermometer-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,1823401043)}):_vm._e(),(_vm.operator)?_c('measurement-item',{attrs:{"title":_vm.operator.title,"datetime":_vm.operator.lastUpdated},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('monitor-signal-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('operator'))+": "),_c('h3',[_vm._v(_vm._s(_vm.operator.value))])]},proxy:true}],null,false,1475308674)}):_vm._e(),(_vm.signal)?_c('measurement-item',{attrs:{"title":_vm.signal.title,"datetime":_vm.signal.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.signal.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('wifi-signal-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('signal'))+": "),_c('h3',[_vm._v(_vm._s(_vm.signal.value)+" dBm")])]},proxy:true}],null,false,3647990079)}):_vm._e(),(_vm.network)?_c('measurement-item',{attrs:{"title":_vm.network.title,"datetime":_vm.network.lastUpdated},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('network-signal-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('network'))+": "),_c('h3',[_vm._v(_vm._s(_vm.network.value))])]},proxy:true}],null,false,3460882928)}):_vm._e(),(_vm.connection)?_c('measurement-item',{attrs:{"title":_vm.connection.title,"datetime":_vm.connection.lastUpdated},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('signal-high-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('connection'))+": "),_c('h3',[_vm._v(_vm._s(_vm.connection.value))])]},proxy:true}],null,false,1960424768)}):_vm._e(),(_vm.wan)?_c('measurement-item',{attrs:{"title":_vm.wan.title,"datetime":_vm.wan.lastUpdated},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('network-pin-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('wan'))+": "),_c('h3',[_vm._v(_vm._s(_vm.wan.value))])]},proxy:true}],null,false,739573657)}):_vm._e(),(_vm.runningTime)?_c('measurement-item',{attrs:{"title":_vm.runningTime.title,"datetime":_vm.runningTime.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.runningTime.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('stopwatch-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('runningTime'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.runningTime.textDuration)+" ")])]},proxy:true}],null,false,2717431076)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }