var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.d1)?_c('measurement-item',{attrs:{"title":_vm.d1.title,"datetime":_vm.d1.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.d1.key,
                labelPair: 'circuit',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('filled-circle-icon',{class:_vm.d1.value ? 'closed' : 'open',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"no-padding-left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.d1.value ? 'd1Closed' : 'd1Open'))+" ")])]},proxy:true}],null,false,3124346799)}):_vm._e(),(_vm.t1)?_c('measurement-item',{attrs:{"title":_vm.t1.title,"datetime":_vm.t1.lastUpdated,"link-to":{
            name: 'cm2humiditychart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('thermometer-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('t1'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.t1.value)+" ")])]},proxy:true}],null,false,3629029907)}):_vm._e(),(_vm.temperature)?_c('measurement-item',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":{
            name: 'cm2humiditychart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('thermometer-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,1823401043)}):_vm._e(),(_vm.humidity)?_c('measurement-item',{attrs:{"title":_vm.humidity.title,"datetime":_vm.humidity.lastUpdated,"link-to":{
            name: 'cm2humiditychart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('drop-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('humidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.humidity.value)+" ")])]},proxy:true}],null,false,2827658156)}):_vm._e(),(_vm.externalHumidity)?_c('measurement-item',{attrs:{"title":_vm.externalHumidity.title,"datetime":_vm.externalHumidity.lastUpdated,"link-to":{
            name: 'cm2humiditychart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('drop-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('externalHumidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.externalHumidity.value)+" ")])]},proxy:true}],null,false,1120653164)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }