<template>
    <div>
        <measurement-item
            v-if="state"
            :title="state.title"
            :datetime="state.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: 'd1',
                    labelPair: 'operation',
                },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="state.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                <h3 class="no-padding-left">
                    {{ $t(state.value ? 'running' : 'notRunning') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="speed"
            :title="speed.title"
            :datetime="speed.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: speed.key,
                },
            }"
        >
            <template #icon>
                <SpeedIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('speed') }}:

                <h3 class="sensor-data-speed">
                    {{ speed.value }}
                </h3>
            </template>
        </measurement-item>

        <battery-measurements-fragment :tracker="tracker" />

        <measurement-item
            v-if="hasRunningTimeAccess && runningTime"
            :title="runningTime.title"
            :datetime="runningTime.lastUpdated"
            :link-to="{
                name: 'runningTimeChart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <stopwatch-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('runningTime') }}:

                <h3>
                    {{ runningTime.textDuration }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatHelper } from '../utils'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import SpeedIcon from './icons/SpeedIcon'
import StopwatchIcon from './icons/RefreshArrowsIcon'

export default {
    name: 'MachineMeasurement',
    components: {
        BatteryMeasurementsFragment,
        FilledCircleIcon,
        MeasurementItem,
        SpeedIcon,
        StopwatchIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        ...mapGetters('authentication', ['hasRunningTimeAccess']),
        state() {
            return this.getMeasurementItem('d1')
        },
        runningTime() {
            const item = this.getMeasurementItem('running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item
        },
        speed() {
            const item = this.getMeasurementItem('speed')
            if (item) {
                item.value = Math.round(item.value * 3.6)
            }
            return item
        },
    },
}
</script>

<i18n>
{
    "en": {
        "running": "Running",
        "notRunning": "Not running",
        "runningTime": "Operating hours",
        "speed": "Speed"
    },
    "de": {
        "running": "In Betrieb",
        "notRunning": "Ausser Betrieb",
        "runningTime": "Betriebsstunden",
        "speed": "Geschwindigkeit"
    },
    "it": {
        "running": "In uso",
        "notRunning": "Fuori uso",
        "runningTime": "Ore di funzionamento",
        "speed": "Velocità"
    }
}
</i18n>

<style lang="scss" scoped>
.measurement-item__content .no-padding-left {
    padding-left: 0;
}

.positive {
    color: $color-green;
}

.negative {
    color: $color-red;
}
</style>
