<template>
    <div>
        <measurement-item
            v-if="state"
            :title="state.title"
            :datetime="state.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: state.key,
                    labelPair: 'alert',
                },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="state.value ? 'negative' : 'positive'"
                />
            </template>

            <template #content>
                <h3>{{ $t(state.value ? 'negative' : 'positive') }}</h3>
            </template>
        </measurement-item>

        <battery-measurements-fragment :tracker="tracker" />
    </div>
</template>

<script>
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'AlertMeasurement',
    components: {
        BatteryMeasurementsFragment,
        FilledCircleIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        state() {
            return this.getMeasurementItem('d1')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "negative": "Alarm",
        "positive": "OK"
    },
    "de": {
        "negative": "Alarm",
        "positive": "OK"
    },
    "it": {
        "negative": "Alarm",
        "positive": "OK"
    }
}
</i18n>

<style lang="scss" scoped>
h3 {
    padding-bottom: 2px;
    padding-left: 0;
    font-size: 20px;
}

.positive {
    color: $color-green;
}

.negative {
    color: $color-red;
}
</style>
