<template>
    <component :is="componentType" :to="linkTo" class="measurement-item">
        <ListItem class="l-center" :clickable="!!linkTo">
            <div class="l-inline l-gap-2 l-center l-grow-1">
                <slot name="icon"></slot>

                <slot name="fullcontent">
                    <div class="l-stack l-grow-1">
                        <div
                            class="l-inline l-center-v measurement-item__content"
                        >
                            <slot name="content"></slot>
                        </div>

                        <TimeAgo
                            v-if="datetime"
                            :from-datetime="datetime"
                            class="measurement-info"
                        />
                    </div>
                </slot>
            </div>

            <p v-if="linkTo">
                <BarChartIcon :width="20" :height="20" color="lightgray" />
            </p>
        </ListItem>
    </component>
</template>

<script>
import BarChartIcon from './icons/BarChartIcon'
import ListItem from './ListItem'
import TimeAgo from './TimeAgo'

export default {
    name: 'MeasurementItem',
    components: {
        BarChartIcon,
        ListItem,
        TimeAgo,
    },
    props: {
        datetime: {
            type: String,
            required: false,
            default: null,
        },
        linkTo: {
            type: Object,
            default: null,
        },
    },
    computed: {
        componentType() {
            return this.linkTo ? 'router-link' : 'div'
        },
    },
}
</script>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}

.measurement-info {
    margin-top: 3px;
    color: #808080;
}

.router-link-active {
    & > div {
        background-color: $color-gray-lighter;
    }
}
</style>

<style lang="scss">
.measurement-item {
    &__content {
        & > * {
            padding-left: 0.25rem;
        }
    }
}
</style>
