<template>
    <div>
        <measurement-item
            v-if="fullness"
            :title="fullness.title"
            :datetime="fullness.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: fullness.key },
                query: { assetType: 'bin' },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="fullness.level"
                />
            </template>

            <template #content>
                <div class="fullness-value">
                    {{ $t('fullness') }}

                    <h3 class="sensor-data-percentage">
                        {{ $t(fullness.level) }} - {{ fullness.value }}
                    </h3>
                </div>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: temperature.key },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <battery-measurements-fragment :tracker="tracker" />
    </div>
</template>

<script>
import { measurementHelper } from '@/utils'

import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'BinMeasurement',
    components: {
        BatteryMeasurementsFragment,
        FilledCircleIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        fullness() {
            const measurement = 'distance'
            const data = this.getMeasurementItem(measurement)

            if (!data) {
                return null
            }

            const value = measurementHelper.convertToBinLevel(
                this.tracker.asset_details.sensor_data[measurement].value
            )

            const [low, height] =
                this.tracker.asset_details.asset_type_identifier ===
                'sbb-bin-paper'
                    ? [20, 35]
                    : [40, 75]

            const level =
                value <= low ? 'low' : value >= height ? 'high' : 'medium'

            return { ...data, value, level }
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "fullness": "Fill value",
        "high": "Full",
        "low": "Empty",
        "medium": "Medium",
        "temperature": "Temperature"
    },
    "de": {
        "fullness": "Füllstand",
        "high": "Voll",
        "low": "Leer",
        "medium": "Mittel",
        "temperature": "Temperatur"
    },
    "it": {
        "fullness": "Livello di riempimento",
        "high": "Completo",
        "low": "Vuoto",
        "medium": "Mezzo",
        "temperature": "Temperatura"
    }
}
</i18n>

<style lang="scss" scoped>
.fullness-value {
    padding: 0;

    h3 {
        padding: 4px 0 2px;
        font-size: 20px;
    }
}

.low {
    color: $color-green;
}

.medium {
    color: $color-orange;
}

.high {
    color: $color-red;
}
</style>
