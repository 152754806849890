<template>
    <div>
        <measurement-item
            v-if="availability"
            :title="availability.title"
            :datetime="availability.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: availability.key },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="availability.value ? 'green' : 'red'"
                />
            </template>

            <template #content>
                <h3 class="measurement-value">
                    {{ availability.value ? $t('free') : $t('occupied') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: temperature.key },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <battery-measurements-fragment :tracker="tracker" />
    </div>
</template>

<script>
import { measurementHelper } from '@/utils'

import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'ParkingSpaceMeasurement',
    components: {
        BatteryMeasurementsFragment,
        FilledCircleIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        availability() {
            const data = this.getMeasurementItem('distance')
            data.value = measurementHelper.convertToParkingAvailability(
                data.value
            )
            return data
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "free": "Free",
            "occupied": "Occupied",
            "temperature": "Temperature"
        },
        "de": {
            "free": "Frei",
            "occupied": "Besetzt",
            "temperature": "Temperatur"
        },
        "it": {
            "free": "Libero",
            "occupied": "Occupato",
            "temperature": "Temperatura"
        }
    }
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}

.measurement-value {
    padding-bottom: 2px;
    padding-left: 0;
    font-size: 20px;
}

.green {
    color: $color-green;
}

.red {
    color: $color-red;
}
</style>
