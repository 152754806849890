<template>
    <div>
        <measurement-item
            v-if="level"
            :title="level.title"
            :datetime="level.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: level.key },
            }"
        >
            <template #icon>
                <water-level-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('level') }}:

                <h3 class="sensor-data-distance">
                    {{ level.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: battery.key },
            }"
        >
            <template #icon>
                <battery-icon
                    :battery-level-in-percent="battery.value"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import BatteryIcon from './icons/BatteryIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import WaterLevelIcon from './icons/WaterLevelIcon'

export default {
    name: 'LevelMeasurement',
    components: {
        BatteryIcon,
        MeasurementItem,
        WaterLevelIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        battery() {
            return this.getMeasurementItem('battery')
        },
        level() {
            return this.getMeasurementItem('level')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "battery": "Battery",
        "level": "Level"
    },
    "de": {
        "battery": "Batterie",
        "level": "Pegel"
    },
    "it": {
        "battery": "Batteria",
        "level": "Livello"
    }
}
</i18n>
