var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.customMeasurementComponent)?_c(_vm.customMeasurementComponent,{tag:"component",attrs:{"tracker":_vm.tracker}}):[(_vm.humidity)?_c('measurement-item',{attrs:{"title":_vm.humidity.title,"datetime":_vm.humidity.lastUpdated,"link-to":_vm.temperature
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: _vm.tracker.id,
                              dataTypes: [_vm.humidity.key, _vm.temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: _vm.tracker.id,
                              dataType: _vm.humidity.key,
                          },
                      }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('humidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.humidity.value)+" ")])]},proxy:true}],null,false,1596388769)}):_vm._e(),(_vm.temperature)?_c('measurement-item',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":_vm.humidity
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: _vm.tracker.id,
                              dataTypes: [_vm.humidity.key, _vm.temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: _vm.tracker.id,
                              dataType: _vm.temperature.key,
                          },
                      }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,239159870)}):_vm._e(),(_vm.distance)?_c('measurement-item',{attrs:{"title":_vm.distance.title,"datetime":_vm.distance.lastUpdated,"link-to":{
                name: 'charts',
                params: { id: _vm.tracker.id, dataType: _vm.distance.key },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DistanceIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('distance'))+": "),_c('h3',{staticClass:"sensor-data-distance"},[_vm._v(" "+_vm._s(_vm.distance.value)+" ")])]},proxy:true}],null,false,1223689488)}):_vm._e(),(_vm.speed)?_c('measurement-item',{attrs:{"title":_vm.speed.title,"datetime":_vm.speed.lastUpdated,"link-to":{
                name: 'charts',
                params: {
                    id: _vm.tracker.id,
                    dataType: _vm.speed.key,
                },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SpeedIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('speed'))+": "),_c('h3',{staticClass:"sensor-data-speed"},[_vm._v(" "+_vm._s(_vm.speed.value)+" ")])]},proxy:true}],null,false,677427088)}):_vm._e(),(_vm.co2)?_c('measurement-item',{attrs:{"title":_vm.co2.title,"datetime":_vm.co2.lastUpdated,"link-to":{
                name: 'charts',
                params: { id: _vm.tracker.id, dataType: _vm.co2.key },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('CO2Icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('co2'))+": "),_c('h3',{staticClass:"sensor-data-co2"},[_vm._v(" "+_vm._s(_vm.co2.value)+" ")])]},proxy:true}],null,false,4293527888)}):_vm._e(),(_vm.voc)?_c('measurement-item',{attrs:{"title":_vm.voc.title,"datetime":_vm.voc.lastUpdated,"link-to":{
                name: 'charts',
                params: { id: _vm.tracker.id, dataType: _vm.voc.key },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('VocIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('voc'))+": "),_c('h3',{staticClass:"sensor-data-voc"},[_vm._v(" "+_vm._s(_vm.voc.value)+" ")])]},proxy:true}],null,false,3326245232)}):_vm._e(),_c('battery-measurements-fragment',{attrs:{"tracker":_vm.tracker}}),(_vm.hasRunningTimeAccess && _vm.runningTime)?_c('measurement-item',{attrs:{"datetime":_vm.runningTime.lastUpdated,"link-to":{
                name: 'runningTimeChart',
                params: { id: _vm.tracker.id },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('stopwatch-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('runningTime'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.runningTime.textDuration)+" ")])]},proxy:true}],null,false,2717431076)}):_vm._e()],(_vm.driverName)?_c('measurement-item',{attrs:{"title":_vm.driverName.title,"datetime":_vm.driverName.lastUpdated},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('person-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"no-padding"},[_vm._v(_vm._s(_vm.driverName.value))])]},proxy:true}],null,false,1283509032)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }