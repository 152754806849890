<template>
    <div>
        <measurement-item
            v-if="d1"
            :title="d1.title"
            :datetime="d1.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: d1.key,
                    labelPair: 'circuit',
                },
            }"
        >
            <template #icon>
                <filled-circle-icon
                    width="24"
                    height="24"
                    :class="d1.value ? 'closed' : 'open'"
                />
            </template>

            <template #content>
                <h3 class="no-padding-left">
                    {{ $t(d1.value ? 'd1Closed' : 'd1Open') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="t1"
            :title="t1.title"
            :datetime="t1.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('t1') }}:

                <h3 class="sensor-data-temperature">
                    {{ t1.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <drop-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="externalHumidity"
            :title="externalHumidity.title"
            :datetime="externalHumidity.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <drop-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('externalHumidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ externalHumidity.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import DropIcon from './icons/DropIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'
import { calculateHumidity } from '@/components/asset-types/cm2-humidity/mixins/humidityCalculation'

export default {
    name: 'CM2HumidityMeasurement',
    components: {
        DropIcon,
        FilledCircleIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        d1() {
            return this.getMeasurementItem('d1')
        },
        externalHumidity() {
            const item = this.getMeasurementItem('ai1')
            item.key = 'external_humidity'
            item.value = calculateHumidity(item.value)
            return item
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        t1() {
            return this.getMeasurementItem('t1')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "d1Open": "DI Open",
        "d1Closed": "DI Closed",
        "externalHumidity": "External humidity",
        "humidity": "Internal humidity",
        "t1": "External temperature",
        "temperature": "Internal temperature"
    },
    "de": {
        "d1Open": "DI Offen",
        "d1Closed": "DI Geschlossen",
        "externalHumidity": "Externe Luftfeuchtigkeit",
        "humidity": "Interne Luftfeuchtigkeit",
        "t1": "Externe Temperatur",
        "temperature": "Interne Temperatur"
    },
    "it": {
        "d1Open": "DI Aperto",
        "d1Closed": "DI Chiuso",
        "externalHumidity": "Umidità esterna",
        "humidity": "Umidità interna",
        "t1": "Temperatura esterna",
        "temperature": "Temperatura interna"
    }
}
</i18n>

<style lang="scss" scoped>
.measurement-item__content .no-padding-left {
    padding-left: 0;
}
.open {
    color: $color-red;
}

.closed {
    color: $color-green;
}
</style>
