var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fullness)?_c('measurement-item',{attrs:{"title":_vm.fullness.title,"datetime":_vm.fullness.lastUpdated,"link-to":{
            name: 'sbbbinchart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('bin-fill-icon',{attrs:{"width":"24","height":"24","fullness":_vm.fullness.value,"fill-color":_vm.colorsMap[_vm.fullness.level]}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fullness'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.fullness.value)+" ")])]},proxy:true}],null,false,3686779948)}):_vm._e(),(_vm.lastCollected)?_c('measurement-item',{attrs:{"title":_vm.lastCollected.title,"datetime":_vm.lastCollected.lastUpdated,"link-to":{
            name: 'sbbbinchart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('trash-bin-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lastCollected'))+": "),(_vm.lastCollected.lastUpdated)?_c('h3',[_c('TimeAgo',{attrs:{"from-datetime":_vm.lastCollected.lastUpdated}})],1):_vm._e()]},proxy:true}],null,false,2644983593)}):_vm._e(),(_vm.temperature)?_c('measurement-item',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.temperature.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,239159870)}):_vm._e(),_c('battery-measurements-fragment',{attrs:{"tracker":_vm.tracker}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }