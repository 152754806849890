var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.battery)?_c('measurement-item',{attrs:{"title":_vm.battery.title,"datetime":_vm.battery.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.battery.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":_vm.battery.value}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.battery'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.battery.value)+" ")])]},proxy:true}],null,false,2608706465)}):_vm._e(),(_vm.batteryStatus)?_c('measurement-item',{attrs:{"title":_vm.batteryStatus.title,"datetime":_vm.batteryStatus.lastUpdated,"link-to":_vm.batteryVoltage
                ? {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.batteryVoltage.key,
                      },
                  }
                : null},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-status":_vm.batteryStatus.value}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.battery'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.$t( 'shared.measurementOptions.battery_status.' + _vm.batteryStatus.value ))+" "),(_vm.batteryVoltage)?[_vm._v(" ("+_vm._s(_vm.batteryVoltage.value)+" V) ")]:_vm._e()],2)]},proxy:true}],null,false,3966303898)}):(!_vm.battery && _vm.batteryVoltage)?_c('measurement-item',{attrs:{"title":_vm.batteryVoltage.title,"datetime":_vm.batteryVoltage.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.batteryVoltage.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":100}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.battery_voltage'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.batteryVoltage.value)+" ")])]},proxy:true}])}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }