var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.power)?_c('measurement-item',{attrs:{"title":_vm.power.title,"datetime":_vm.power.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.power.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('flash-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('power'))+": "),_c('h3',{staticClass:"sensor-data-w"},[_vm._v(" "+_vm._s(_vm.power.value)+" ")])]},proxy:true}],null,false,2467339770)}):_vm._e(),(_vm.power0)?_c('measurement-item',{attrs:{"title":_vm.power0.title,"datetime":_vm.power0.lastUpdated,"link-to":{
            name: 'chartsCombined',
            params: {
                id: _vm.tracker.id,
                dataTypes: _vm.combinedChartKeys,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('flash-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('power0'))+": "),_c('h3',{staticClass:"sensor-data-w"},[_vm._v(" "+_vm._s(_vm.power0.value)+" ")])]},proxy:true}],null,false,630923866)}):_vm._e(),(_vm.power1)?_c('measurement-item',{attrs:{"title":_vm.power1.title,"datetime":_vm.power1.lastUpdated,"link-to":{
            name: 'chartsCombined',
            params: {
                id: _vm.tracker.id,
                dataTypes: _vm.combinedChartKeys,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('flash-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('power1'))+": "),_c('h3',{staticClass:"sensor-data-w"},[_vm._v(" "+_vm._s(_vm.power1.value)+" ")])]},proxy:true}],null,false,1807472762)}):_vm._e(),(_vm.power2)?_c('measurement-item',{attrs:{"title":_vm.power2.title,"datetime":_vm.power2.lastUpdated,"link-to":{
            name: 'chartsCombined',
            params: {
                id: _vm.tracker.id,
                dataTypes: _vm.combinedChartKeys,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('flash-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('power2'))+": "),_c('h3',{staticClass:"sensor-data-w"},[_vm._v(" "+_vm._s(_vm.power2.value)+" ")])]},proxy:true}],null,false,240833690)}):_vm._e(),(_vm.power3)?_c('measurement-item',{attrs:{"title":_vm.power3.title,"datetime":_vm.power3.lastUpdated,"link-to":{
            name: 'chartsCombined',
            params: {
                id: _vm.tracker.id,
                dataTypes: _vm.combinedChartKeys,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('flash-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('power3'))+": "),_c('h3',{staticClass:"sensor-data-w"},[_vm._v(" "+_vm._s(_vm.power3.value)+" ")])]},proxy:true}],null,false,2652202170)}):_vm._e(),(_vm.temperature)?_c('measurement-item',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.temperature.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('thermometer-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,1823401043)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }