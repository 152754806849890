<template>
    <div>
        <measurement-item
            v-if="batteryPercentage"
            :title="batteryPercentage.title"
            :datetime="batteryPercentage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: batteryPercentage.key,
                },
            }"
        >
            <template #icon>
                <battery-icon
                    :battery-level-in-percent="batteryPercentage.value"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>

            <template #content>
                {{ $t('batteryPercentage') }}:

                <h3 class="sensor-data-percentage">
                    {{ batteryPercentage.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-else-if="batteryStatus"
            :title="batteryStatus.title"
            :datetime="batteryStatus.lastUpdated"
            :link-to="
                batteryVoltage
                    ? {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: batteryVoltage.key,
                          },
                      }
                    : null
            "
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-status="batteryStatus.value"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3>
                    {{
                        $t(
                            'shared.measurementOptions.battery_status.' +
                                batteryStatus.value
                        )
                    }}

                    <template v-if="batteryVoltage">
                        ({{ batteryVoltage.value }} V)
                    </template>
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-else-if="!batteryPercentage && batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: batteryVoltage.key,
                },
            }"
        >
            <template #icon>
                <flash-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('batteryVoltage') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: temperature.key,
                },
            }"
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperatureAfe"
            :title="temperatureAfe.title"
            :datetime="temperatureAfe.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: temperatureAfe.key,
                },
            }"
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperatureAfe') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperatureAfe.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="runningTime"
            :title="runningTime.title"
            :datetime="runningTime.lastUpdated"
        >
            <template #icon>
                <stopwatch-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('runningTime') }}:

                <h3>
                    {{ runningTime.textDuration }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="powerUpCounter"
            :title="powerUpCounter.title"
            :datetime="powerUpCounter.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: powerUpCounter.key,
                },
            }"
        >
            <template #icon>
                <line-chart-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('powerUpCounter') }}:

                <h3>
                    {{ powerUpCounter.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import { formatHelper } from '../utils'
import BatteryIcon from './icons/BatteryIcon'
import FlashIcon from './icons/FlashIcon'
import LineChartIcon from './icons/LineChartIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import StopwatchIcon from './icons/RefreshArrowsIcon'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'EcocoachPowerTrolleyMeasurement',
    components: {
        BatteryIcon,
        FlashIcon,
        LineChartIcon,
        MeasurementItem,
        StopwatchIcon,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        batteryPercentage() {
            return this.getMeasurementItem('battery_percentage')
        },
        batteryVoltage() {
            return this.getMeasurementItem('voltage_0')
        },
        powerUpCounter() {
            return this.getMeasurementItem('d1_count')
        },
        runningTime() {
            const item = this.getMeasurementItem('running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item
        },
        temperature() {
            return this.getMeasurementItem('temperature_0')
        },
        temperatureAfe() {
            return this.getMeasurementItem('temperature_1')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "batteryPercentage": "State of Charge",
        "batteryVoltage": "Battery Voltage",
        "powerUpCounter": "Power up counter",
        "runningTime": "Operating hours",
        "temperature": "Battery Temperature",
        "temperatureAfe": "AFE Temperature"
    },
    "de": {
        "batteryPercentage": "Ladezustand",
        "batteryVoltage": "Batteriespannung",
        "powerUpCounter": "Power up counter",
        "runningTime": "Betriebsstunden",
        "temperature": "Batterietemperatur",
        "temperatureAfe": "AFE Temperatur"
    },
    "it": {
        "batteryPercentage": "State of Charge",
        "batteryVoltage": "Livello dell batteria",
        "powerUpCounter": "Power up counter",
        "runningTime": "Ore di funzionamento",
        "temperature": "Battery Temperatura",
        "temperatureAfe": "AFE Temperatura"
    }
}
</i18n>
