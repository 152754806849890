<template>
    <div>
        <measurement-item
            v-if="d1"
            :title="d1.title"
            :datetime="d1.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: d1.key,
                    labelPair: 'malfunction',
                },
            }"
        >
            <template #icon>
                <filled-circle-icon
                    width="24"
                    height="24"
                    :class="d1.value ? 'negative' : 'positive'"
                />
            </template>

            <template #content>
                <h3 class="no-padding-left">
                    {{ $t(d1.value ? 'd1NotOK' : 'd1OK') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="d2"
            :title="d2.title"
            :datetime="d2.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: d2.key,
                    labelPair: 'maintenance',
                },
            }"
        >
            <template #icon>
                <filled-circle-icon
                    width="24"
                    height="24"
                    :class="d2.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                <h3 class="no-padding-left">
                    {{ $t(d2.value ? 'd2OK' : 'd2NotOK') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="d3"
            :title="d3.title"
            :datetime="d3.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: d3.key,
                    labelPair: 'door',
                },
            }"
        >
            <template #icon>
                <filled-circle-icon
                    width="24"
                    height="24"
                    :class="d3.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                <h3 class="no-padding-left">
                    {{ $t(d3.value ? 'd3NotOK' : 'd3OK') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="d4"
            :title="d4.title"
            :datetime="d4.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: d4.key,
                    labelPair: 'flood',
                },
            }"
        >
            <template #icon>
                <filled-circle-icon
                    width="24"
                    height="24"
                    :class="d4.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                <h3 class="no-padding-left">
                    {{ $t(d4.value ? 'd4OK' : 'd4NotOK') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="t1"
            :title="t1.title"
            :datetime="t1.lastUpdated"
            :link-to="
                t2 && t3 && t4
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [t1.key, t2.key, t3.key, t4.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: t1.key,
                          },
                      }
            "
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('t1') }}:

                <h3 class="sensor-data-temperature">
                    {{ t1.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="t2"
            :title="t2.title"
            :datetime="t2.lastUpdated"
            :link-to="
                t1 && t3 && t4
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [t1.key, t2.key, t3.key, t4.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: t2.key,
                          },
                      }
            "
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('t2') }}:

                <h3 class="sensor-data-temperature">
                    {{ t2.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="t3"
            :title="t3.title"
            :datetime="t3.lastUpdated"
            :link-to="
                t1 && t2 && t4
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [t1.key, t2.key, t3.key, t4.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: t3.key,
                          },
                      }
            "
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('t3') }}:

                <h3 class="sensor-data-temperature">
                    {{ t3.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="t4"
            :title="t4.title"
            :datetime="t4.lastUpdated"
            :link-to="
                t1 && t2 && t3
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [t1.key, t2.key, t3.key, t4.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: t4.key,
                          },
                      }
            "
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('t4') }}:

                <h3 class="sensor-data-temperature">
                    {{ t4.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="hasRunningTimeAccess && runningTime"
            :title="runningTime.title"
            :datetime="runningTime.lastUpdated"
            :link-to="{
                name: 'runningTimeChart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <stopwatch-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('runningTime') }}:

                <h3>
                    {{ runningTime.textDuration }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatHelper } from '../utils'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import StopwatchIcon from './icons/RefreshArrowsIcon'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'HeatingSystemMeasurement',
    components: {
        FilledCircleIcon,
        MeasurementItem,
        StopwatchIcon,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        ...mapGetters('authentication', [
            'hasRunningTimeAccess',
            'isAdminOrStaff',
        ]),
        d1() {
            return this.getMeasurementItem('d1')
        },
        d2() {
            return this.getMeasurementItem('d2')
        },
        d3() {
            return this.getMeasurementItem('d3')
        },
        d4() {
            return this.getMeasurementItem('d4')
        },
        t1() {
            return this.getMeasurementItem('t1')
        },
        t2() {
            return this.getMeasurementItem('t2')
        },
        t3() {
            return this.getMeasurementItem('t3')
        },
        t4() {
            return this.getMeasurementItem('t4')
        },
        runningTime() {
            const item = this.getMeasurementItem('running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item
        },
    },
}
</script>

<i18n>
{
    "en": {
        "d1OK": "Status OK",
        "d1NotOK": "Malfunction",
        "d2OK": "No maintenance needed",
        "d2NotOK": "Maintenance needed",
        "d3OK": "Door open",
        "d3NotOK": "Door closed",
        "d4OK": "No flood",
        "d4NotOK": "Flood detected",
        "runningTime": "Operating hours",
        "t1": "Inlet temperature",
        "t2": "Outlet temperature",
        "t3": "Room temperature",
        "t4": "Outdoor temperature"
    },
    "de": {
        "d1OK": "Status OK",
        "d1NotOK": "Störung",
        "d2OK": "Keine Wartung erforderlich",
        "d2NotOK": "Wartung erforderlich",
        "d3OK": "Tür offen",
        "d3NotOK": "Tür zu",
        "d4OK": "Keine Überflutung",
        "d4NotOK": "Überflutung erkannt",
        "runningTime": "Betriebsstunden",
        "t1": "Vorlauftemperatur",
        "t2": "Rücklauftemperatur",
        "t3": "Raumtemperatur",
        "t4": "Aussentemperatur"
    },
    "it": {
        "d1OK": "OK",
        "d1NotOK": "Malfunction",
        "d2OK": "No maintenance needed",
        "d2NotOK": "Maintenance needed",
        "d3OK": "Door open",
        "d3NotOK": "Door closed",
        "d4OK": "No flood",
        "d4NotOK": "Flood detected",
        "runningTime": "Ore di funzionamento",
        "t1": "Temperatura di mandata",
        "t2": "Temperatura di ritorno",
        "t3": "Temperatura ambiente",
        "t4": "Temperatura esterna"
    }
}
</i18n>

<style lang="scss" scoped>
.measurement-item__content .no-padding-left {
    padding-left: 0;
}
.positive {
    color: $color-green;
}

.negative {
    color: $color-red;
}
</style>
