var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.waterLevel)?_c('measurement-item',{attrs:{"title":_vm.waterLevel.title,"datetime":_vm.waterLevel.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.waterLevel.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('bar-chart-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('waterLevel'))+": "),_c('h3',{staticClass:"sensor-data-distance-mm"},[_vm._v(" "+_vm._s(_vm.waterLevel.value)+" ")])]},proxy:true}],null,false,1836486947)}):_vm._e(),(_vm.humidity)?_c('measurement-item',{attrs:{"title":_vm.humidity.title,"datetime":_vm.humidity.lastUpdated,"link-to":_vm.temperature
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                          dataTypes: [_vm.humidity.key, _vm.temperature.key],
                      },
                  }
                : {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.humidity.key,
                      },
                  }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('drop-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('humidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.humidity.value)+" ")])]},proxy:true}],null,false,2827658156)}):_vm._e(),(_vm.temperature)?_c('measurement-item',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":_vm.humidity
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                          dataTypes: [_vm.humidity.key, _vm.temperature.key],
                      },
                  }
                : {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.temperature.key,
                      },
                  }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('thermometer-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,1823401043)}):_vm._e(),_c('battery-measurements-fragment',{attrs:{"tracker":_vm.tracker}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }