<template>
    <div>
        <measurement-item
            v-if="waterLevel"
            :title="waterLevel.title"
            :datetime="waterLevel.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: waterLevel.key },
            }"
        >
            <template #icon>
                <bar-chart-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('waterLevel') }}:

                <h3 class="sensor-data-distance-mm">
                    {{ waterLevel.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="
                temperature
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [humidity.key, temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: humidity.key,
                          },
                      }
            "
        >
            <template #icon>
                <drop-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="
                humidity
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [humidity.key, temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: temperature.key,
                          },
                      }
            "
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <battery-measurements-fragment :tracker="tracker" />
    </div>
</template>

<script>
import BarChartIcon from './icons/BarChartIcon'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import DropIcon from './icons/DropIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'FlatRoofMeasurement',
    components: {
        BarChartIcon,
        BatteryMeasurementsFragment,
        DropIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        waterLevel() {
            return this.getMeasurementItem('water_level')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "humidity": "Humidity",
        "temperature": "Temperature",
        "waterLevel": "Water level"
    },
    "de": {
        "humidity": "Luftfeuchtigkeit",
        "temperature": "Temperatur",
        "waterLevel": "Wasserpegel"
    },
    "it": {
        "humidity": "Humidity",
        "temperature": "Temperatura",
        "waterLevel": "Livello dell'acqua"
    }
}
</i18n>
