var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fillLevel)?_c('measurement-item',{attrs:{"title":_vm.fillLevel.title,"datetime":_vm.fillLevel.lastUpdated,"link-to":{
            name: 'dynamicbinchart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('bin-fill-icon',{attrs:{"width":"24","height":"24","fullness":_vm.fillLevel.value,"fill-color":_vm.colorsMap[_vm.fillLevel.level]}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.fill_level'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.fillLevel.value)+" ")])]},proxy:true}],null,false,35739267)}):_vm._e(),(_vm.lastCollected)?_c('measurement-item',{attrs:{"title":_vm.lastCollected.title,"datetime":_vm.lastCollected.lastUpdated,"link-to":{
            name: 'dynamicbinchart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('trash-bin-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lastCollected'))+": "),(_vm.lastCollected.lastUpdated)?_c('h3',[_c('TimeAgo',{attrs:{"from-datetime":_vm.lastCollected.lastUpdated}})],1):_vm._e()]},proxy:true}],null,false,2644983593)}):_vm._e(),(_vm.battery)?_c('measurement-item',{attrs:{"title":_vm.battery.title,"datetime":_vm.battery.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.battery.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":_vm.battery.value}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.battery'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.battery.value)+" ")])]},proxy:true}],null,false,2608706465)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }