<template>
    <div>
        <measurement-item
            v-if="fillLevel"
            :title="fillLevel.title"
            :datetime="fillLevel.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: fillLevel.key },
            }"
        >
            <template #icon>
                <bin-fill-icon
                    width="24"
                    height="24"
                    :fullness="fillLevel.value"
                    :fill-color="fillLevel.value >= 20 ? '#41b883' : '#d32f2f'"
                />
            </template>

            <template #content>
                {{ $t('fillLevel') }}:

                <h3 class="sensor-data-percentage">
                    {{ fillLevel.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="mass"
            :title="mass.title"
            :datetime="mass.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: mass.key },
            }"
        >
            <template #icon>
                <scales-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('mass') }}:

                <h3>{{ mass.value }} t</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="volume"
            :title="volume.title"
            :datetime="volume.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: volume.key },
            }"
        >
            <template #icon>
                <cylinder-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('volume') }}:

                <h3>{{ volume.value }} m³</h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import BinFillIcon from './icons/BinFillIcon'
import CylinderIcon from './icons/CylinderIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ScalesIcon from './icons/ScalesIcon'

export default {
    name: 'SiloMeasurement',
    components: {
        BinFillIcon,
        CylinderIcon,
        MeasurementItem,
        ScalesIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        fillLevel() {
            const data = this.getMeasurementItem('fill_level')
            if (data) {
                data.value = Math.round(data.value * 100)
            }
            return data
        },
        mass() {
            const data = this.getMeasurementItem('mass')
            if (data) {
                data.value = (data.value / 1000).toFixed(2)
            }
            return data
        },
        volume() {
            return this.getMeasurementItem('volume')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "fillLevel": "Fill level",
        "mass": "Mass",
        "volume": "Volume"
    },
    "de": {
        "fillLevel": "Füllstand",
        "mass": "Masse",
        "volume": "Volumen"
    },
    "it": {
        "fillLevel": "Livello di riempimento",
        "mass": "Massa",
        "volume": "Volume"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}
</style>
