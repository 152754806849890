import moment from 'moment-timezone'

export default {
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getItemTitle(measurement, lastUpdate) {
            const date = moment(lastUpdate).format('DD.MM.YYYY HH:mm')
            const translationKey = `shared.measurements.${measurement}`
            let key = this.$root.$te(translationKey)
                ? this.$t(translationKey)
                : measurement

            if (measurement === 'temperature') {
                key = this.$t('shared.measurements.t0')
            }

            return `${key} | ${this.$t('shared.lastMeasurement')}: ${date}`
        },
        getMeasurementItem(measurement) {
            const data = this.tracker.asset_details.sensor_data[measurement]

            if (!data) {
                return null
            }

            return {
                key: measurement,
                title:
                    data.last_update &&
                    this.getItemTitle(measurement, data.last_update),
                value: data.value,
                lastUpdated: data.last_update,
            }
        },
    },
}
