<template>
    <div>
        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: temperature.key,
                },
            }"
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="operator"
            :title="operator.title"
            :datetime="operator.lastUpdated"
        >
            <template #icon>
                <monitor-signal-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('operator') }}:

                <h3>{{ operator.value }}</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="signal"
            :title="signal.title"
            :datetime="signal.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: signal.key,
                },
            }"
        >
            <template #icon>
                <wifi-signal-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('signal') }}:

                <h3>{{ signal.value }} dBm</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="network"
            :title="network.title"
            :datetime="network.lastUpdated"
        >
            <template #icon>
                <network-signal-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('network') }}:

                <h3>{{ network.value }}</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="connection"
            :title="connection.title"
            :datetime="connection.lastUpdated"
        >
            <template #icon>
                <signal-high-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('connection') }}:

                <h3>{{ connection.value }}</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="wan"
            :title="wan.title"
            :datetime="wan.lastUpdated"
        >
            <template #icon>
                <network-pin-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('wan') }}:

                <h3>{{ wan.value }}</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="runningTime"
            :title="runningTime.title"
            :datetime="runningTime.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: runningTime.key,
                },
            }"
        >
            <template #icon>
                <stopwatch-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('runningTime') }}:

                <h3>
                    {{ runningTime.textDuration }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import { formatHelper } from '../utils'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import MonitorSignalIcon from './icons/MonitorSignalIcon'
import NetworkPinIcon from './icons/NetworkPinIcon'
import NetworkSignalIcon from './icons/NetworkSignalIcon'
import SignalHighIcon from './icons/SignalHighIcon'
import StopwatchIcon from './icons/RefreshArrowsIcon'
import ThermometerIcon from './icons/ThermometerIcon'
import WifiSignalIcon from './icons/WifiSignalIcon'

export default {
    name: 'TeltonikaRouterMeasurement',
    components: {
        MeasurementItem,
        MonitorSignalIcon,
        NetworkPinIcon,
        NetworkSignalIcon,
        SignalHighIcon,
        StopwatchIcon,
        ThermometerIcon,
        WifiSignalIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        connection() {
            return this.getMeasurementItem('connection')
        },
        operator() {
            return this.getMeasurementItem('operator')
        },
        network() {
            return this.getMeasurementItem('network')
        },
        runningTime() {
            const item = this.getMeasurementItem('running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item
        },
        signal() {
            return this.getMeasurementItem('signal')
        },
        state() {
            return this.getMeasurementItem('d1')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        wan() {
            return this.getMeasurementItem('wan')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "connection": "Connection",
        "network": "Network",
        "operator": "Operator",
        "runningTime": "Operating hours",
        "signal": "Signal",
        "temperature": "Temperature",
        "wan": "WAN IP"
    },
    "de": {
        "connection": "Connection",
        "network": "Netzwerk",
        "operator": "Operator",
        "runningTime": "Betriebsstunden",
        "signal": "Signal",
        "temperature": "Temperatur",
        "wan": "WAN IP"
    },
    "it": {
        "connection": "Connection",
        "network": "Rete",
        "operator": "Operator",
        "runningTime": "Ore di funzionamento",
        "signal": "Signal",
        "temperature": "Temperatura",
        "wan": "WAN IP"
    }
}
</i18n>
