<template>
    <div>
        <div v-if="!entry" class="l-padded">
            <p>{{ $t('assetDoesNotExist') }}</p>
        </div>

        <template v-else>
            <div class="u-bb">
                <AssetDetailOverview
                    :tracker="entryDetailed || entry"
                    :show-edit-button="true"
                />
            </div>

            <div
                v-if="
                    entry.location_details ||
                        (entry.asset_details &&
                            entry.asset_details.position.latitude &&
                            entry.asset_details.position.longitude)
                "
                class="u-bb"
            >
                <div class="l-padded u-bb">{{ $t('positionTitle') }}</div>

                <div v-if="showCurrentLocations && currentLocations">
                    <router-link
                        v-for="loc in currentLocations"
                        :key="loc.id"
                        :to="{
                            name: 'location',
                            params: { id: loc.id },
                        }"
                    >
                        <ListItem class="l-center">
                            <div class="l-inline l-gap-2 l-center l-grow-1">
                                <pin-location-icon
                                    :name="$t('currentLocation')"
                                    width="24"
                                    height="24"
                                />
                                <div class="l-stack l-grow-1">
                                    <h3>{{ loc.name }}</h3>
                                    <TimeAgo
                                        v-if="last_gps_position_timestamp"
                                        :from-datetime="
                                            last_gps_position_timestamp
                                        "
                                        class="item-info"
                                    />
                                </div>
                            </div>

                            <p><i class="arrow right"></i></p>
                        </ListItem>
                    </router-link>
                </div>

                <router-link
                    v-if="entry.location_details"
                    :to="{
                        name: 'location',
                        params: { id: entry.location_details.id },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2">
                            <pin-home-icon
                                :name="$t('homeLocation')"
                                width="24"
                                height="24"
                            />
                            <div class="l-stack l-gap-1 l-center">
                                <h3>
                                    {{
                                        entry.location_details &&
                                            entry.location_details.name
                                    }}
                                </h3>
                            </div>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>

                <a
                    v-if="showGoogleMapsLink"
                    :href="
                        'http://www.google.com/maps/place/' +
                            entry.asset_details.position.latitude +
                            ',' +
                            entry.asset_details.position.longitude
                    "
                    class="t-underline"
                    target="_blank"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center">
                            <open-in-new-icon width="24" height="24" />

                            <div class="l-stack l-gap-1">
                                <div>
                                    <a
                                        class="t-underline"
                                        :href="
                                            'http://www.google.com/maps/place/' +
                                                entry.asset_details.position
                                                    .latitude +
                                                ',' +
                                                entry.asset_details.position
                                                    .longitude
                                        "
                                        target="_blank"
                                    >
                                        {{ $t('showOnMap') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ListItem>
                </a>

                <router-link
                    v-if="
                        isSuperuser &&
                            entryDetailed &&
                            entryDetailed.asset_details.schema_url
                    "
                    :to="{ name: 'assetScheme' }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2">
                            <grid-ruler-icon width="24" height="24" />

                            <div class="l-stack l-gap-1 l-center">
                                <h3>
                                    {{ $t('showScheme') }}
                                </h3>
                            </div>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>

                <router-link
                    v-if="
                        supportsTrips !== undefined &&
                            entry.last_gps_measurement &&
                            entry.last_gps_measurement.lat &&
                            entry.last_gps_measurement.lng
                    "
                    :to="{
                        name: supportsTrips ? 'triphistory' : 'locationhistory',
                        params: {
                            id,
                            isTripModePossible: supportsTrips !== undefined,
                        },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2">
                            <clock-icon width="24" height="24" />

                            <div class="l-stack l-gap-1 l-center">
                                <h3>
                                    {{
                                        supportsTrips
                                            ? $t('triphistory')
                                            : $t('history')
                                    }}
                                </h3>
                            </div>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>

                <router-link
                    v-if="isLocationHistoryChartAvailable"
                    :to="{ name: 'assetLocationHistoryChart' }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2">
                            <time-clock-file-search width="24" height="24" />

                            <div class="l-stack l-gap-1 l-center">
                                <h3>
                                    {{ $t('router.assetLocationHistoryChart') }}
                                </h3>
                            </div>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>

                <ListItem v-if="!entry.asset_details.static" class="l-center-v">
                    <div>{{ $t('followTrackerUpdates') }}</div>

                    <toggle-button
                        :value="shouldFollowActiveTrackerUpdates"
                        :sync="true"
                        color="#02a5e1"
                        :width="40"
                        :height="24"
                        :speed="150"
                        @input="setShouldFollowActiveTrackerUpdates"
                    />
                </ListItem>
            </div>

            <div v-if="isMeasurementsAvailable" class="u-bb">
                <div class="l-padded u-bb">{{ $t('measurements') }}</div>

                <MeasurementsList :tracker="entry" />
            </div>

            <downlinks-list :id="id" class="u-bb" />

            <asset-notifications
                v-if="hasRulesAccess"
                :asset="entry.asset"
                class="u-bb"
            />

            <hidden-form
                v-if="entryDetailed && entryDetailed.config"
                class="u-bb"
                :title-class="{ 'u-bb': !isConfigListHidden }"
                :hidden="isConfigListHidden"
                @toggle="isConfigListHidden = $event"
            >
                <template #title>
                    {{ $t('configuration') }}
                </template>

                <template #form>
                    <asset-config-list :asset="entryDetailed" />
                </template>
            </hidden-form>

            <asset-documents
                :asset="entry.asset"
                class="u-bb"
                :show-edit-actions="userHasEditRights"
            />

            <div v-if="hasDebugAccess" class="u-bb">
                <div class="l-padded u-bb">{{ $t('diagnostics') }}</div>
                <div v-if="isSuperuser">
                    <router-link
                        :to="{
                            name: 'assetConnectionHeatmap',
                            params: { assetId: id },
                        }"
                    >
                        <ListItem>
                            <div class="l-inline l-gap-2">
                                <heatmap-icon width="24" height="24" />

                                <div class="l-stack l-gap-1 l-center">
                                    <h3>{{ $t('connectionHeatmap') }}</h3>
                                </div>
                            </div>

                            <p><i class="arrow right"></i></p>
                        </ListItem>
                    </router-link>
                </div>

                <router-link
                    :to="{
                        name: 'networkChart',
                        params: { assetId: id },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2">
                            <network-signal-icon width="24" height="24" />

                            <div class="l-stack l-gap-1 l-center">
                                <h3>{{ $t('signalQuality') }}</h3>
                            </div>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>
            </div>
        </template>

        <transition name="slide-from-right">
            <div v-if="isDetachedPanelActive" class="detached-panel">
                <div
                    class="l-inline l-center-v l-spread l-padded u-bb detached-panel-header"
                >
                    {{ detachedPanelTitle }}

                    <router-link :to="{ name: 'detail', params: { id } }">
                        <icon-button @click="$emit('expand')">
                            <remove-icon width="24" height="24" color="black" />
                        </icon-button>
                    </router-link>
                </div>

                <div class="detached-panel-body">
                    <router-view />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { ToggleButton } from 'vue-js-toggle-button'

import { httpHelper } from '@/utils'

import AssetConfigList from '../AssetConfigList'
import AssetDetailOverview from '../AssetDetailOverview'
import AssetDocuments from '../AssetDocuments'
import AssetNotifications from '../AssetNotifications'
import ClockIcon from '../icons/HistoryClockIcon'
import DownlinksList from '../DownlinksList'
import GridRulerIcon from '../icons/GridRulerIcon'
import HeatmapIcon from '../icons/HeatmapIcon'
import HiddenForm from '../HiddenForm'
import IconButton from '../IconButton'
import ListItem from '../ListItem'
import MeasurementsList from '../MeasurementsList'
import NetworkSignalIcon from '../icons/NetworkSignalIcon'
import OpenInNewIcon from '../icons/OpenInNewIcon'
import PinHomeIcon from '../icons/PinHomeIcon'
import PinLocationIcon from '../icons/PinLocationIcon'
import RemoveIcon from '../icons/RemoveIcon'
import TimeAgo from '../TimeAgo'
import TimeClockFileSearch from '../icons/TimeClockFileSearch'

export default {
    name: 'AssetDetailView',
    components: {
        AssetConfigList,
        AssetDetailOverview,
        AssetDocuments,
        AssetNotifications,
        ClockIcon,
        DownlinksList,
        GridRulerIcon,
        HeatmapIcon,
        HiddenForm,
        IconButton,
        ListItem,
        MeasurementsList,
        NetworkSignalIcon,
        OpenInNewIcon,
        PinHomeIcon,
        PinLocationIcon,
        RemoveIcon,
        TimeAgo,
        TimeClockFileSearch,
        ToggleButton,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            currentLocations: [],
            entryDetailed: null,
            isConfigListHidden: true,
        }
    },
    computed: {
        ...mapState('locations', ['locations']),
        ...mapState('tracker', [
            'shouldFollowActiveTrackerUpdates',
            'trackers',
        ]),
        ...mapGetters('authentication', [
            'hasDebugAccess',
            'hasLocationHistoryAccess',
            'hasRulesAccess',
            'isSuperuser',
            'userInfo',
        ]),
        supportsTrips() {
            return this.entryDetailed?.asset_details?.supports_trips
        },
        detachedPanelTitle() {
            if (
                this.$route.name === 'chartsCombined' &&
                this.$route.params.dataTypes?.length
            ) {
                if (
                    this.$route.params.dataTypes.every(type =>
                        /^voltage_\d+$/.test(type)
                    )
                ) {
                    return this.$t('router.fenceGuardVoltage')
                }

                if (
                    this.$route.params.dataTypes.every(type =>
                        /^t\d+$/.test(type)
                    )
                ) {
                    return this.$t('router.temperature')
                }

                return this.$route.params.dataTypes
                    .map(item => this.$t(`router.${item}`))
                    .join(' & ')
            }

            let prefix = ''

            switch (this.entry?.asset_details.asset_type_type) {
                case 'ecocoach-power-trolley':
                    prefix = 'ecocoach_'
                    break
            }

            const routeName =
                this.$route.name === 'charts'
                    ? this.$route.params.dataType
                    : this.$route.name

            return this.$root.$te(`router.${prefix}${routeName}`)
                ? this.$t(`router.${prefix}${routeName}`)
                : this.$t(`router.${routeName}`)
        },
        entry() {
            return this.trackers.find(entry => entry.id == this.id)
        },
        userHasEditRights() {
            return this.entry?.asset_details?.permissions?.access === 'WRITE'
        },
        isDetachedPanelActive() {
            return (
                this.$route.meta?.isChart || this.$route.name === 'assetScheme'
            )
        },
        isLocationHistoryChartAvailable() {
            return (
                this.hasLocationHistoryAccess &&
                this.entryDetailed?.asset_details.location_history_enabled
            )
        },
        isMeasurementsAvailable() {
            return (
                this.entry?.asset_details?.sensor_data &&
                Object.keys(this.entry.asset_details.sensor_data).length
            )
        },
        showGoogleMapsLink() {
            return (
                this.entry.asset_details?.position?.latitude &&
                this.entry.asset_details?.position?.longitude &&
                process.env.VUE_APP_ENVIRONMENT_NAME !== 'orkanet'
            )
        },
        showCurrentLocations() {
            return (
                this.isSuperuser &&
                this.entryDetailed?.asset_details?.location_history_enabled
            )
        },
        last_gps_position_timestamp() {
            return this.entryDetailed?.last_gps_measurement?.timestamp
        },
    },
    watch: {
        entry() {
            this.setActiveTrackerOnMap(this.entry)

            if (this.entryDetailed) {
                this.entryDetailed.asset_details.sensor_data = this.entry.asset_details.sensor_data
                this.entryDetailed.last_message_timestamp = this.entry.last_message_timestamp
                this.entryDetailed.last_gps_measurement = this.entry.last_gps_measurement
            }
        },
        id() {
            this.fetchAssetDetails()
        },
    },
    async mounted() {
        if (!this.entry) {
            return
        }

        this.setActiveTrackerOnMap(this.entry)
        this.setShouldFollowActiveTrackerUpdates(true)
        this.fetchAssetDetails()
    },
    beforeRouteLeave(to, from, next) {
        if (to.name !== 'editAsset' && to.params.id !== this.entry?.asset) {
            this.setActiveTrackerOnMap(null)
        }

        next()
    },
    sockets: {
        position(data) {
            if (data.tracker === this.entry.id) {
                this.updateCurrentLocations(data.current_locations)
            }
        },
    },
    methods: {
        ...mapMutations('tracker', [
            'setActiveTrackerOnMap',
            'setShouldFollowActiveTrackerUpdates',
        ]),
        async fetchAssetDetails() {
            this.entryDetailed = null
            const { data } = await httpHelper.get(`trackers/${this.id}/`)
            this.entryDetailed = data
            this.updateCurrentLocations(data.asset_details.current_locations)
        },
        updateCurrentLocations(ids = []) {
            if (this.showCurrentLocations) {
                this.currentLocations = ids
                    .map(id => this.locations.find(item => item.id === id))
                    .filter(Boolean)
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "assetDoesNotExist": "This asset does not exist.",
        "configuration": "Configuration",
        "connectionHeatmap": "Connection Heatmap",
        "currentLocation": "Current location",
        "diagnostics": "Diagnostics",
        "documents": "Documents",
        "followTrackerUpdates": "Follow position updates",
        "history": "History",
        "homeLocation": "Home location",
        "triphistory": "Trip History",
        "measurements": "Measurements",
        "notifications": "Notifications",
        "positionTitle": "Position information",
        "settings": "Settings",
        "showOnMap": "Show on Google Maps",
        "showScheme": "Show Schema",
        "signalQuality": "Signal Quality",
        "tracking": "Tracking"
    },
    "de": {
        "assetDoesNotExist": "Dieses Asset existiert nicht.",
        "configuration": "Konfiguration",
        "connectionHeatmap": "Verbindungsqualität",
        "currentLocation": "Aktueller Standort",
        "diagnostics": "Diagnose",
        "documents": "Dokumente",
        "followTrackerUpdates": "Live Position verfolgen",
        "history": "Verlauf",
        "homeLocation": "Home Standort",
        "triphistory": "Fahrtenverlauf",
        "measurements": "Messungen",
        "notifications": "Benachrichtigungen",
        "positionTitle": "Positionsinformationen",
        "settings": "Einstellungen",
        "showOnMap": "Auf Google Maps anzeigen",
        "showScheme": "Schema anzeigen",
        "signalQuality": "Signal Qualität",
        "tracking": "Tracking"
    },
    "it": {
        "assetDoesNotExist": "Questo asset non esiste.",
        "configuration": "Configurazione",
        "connectionHeatmap": "Qualità della connessione",
        "currentLocation": "Locazione attuale",
        "diagnostics": "Diagnostica",
        "documents": "Documenti",
        "followTrackerUpdates": "Seguire la posizione",
        "history": "Storico",
        "homeLocation": "Home location",
        "triphistory": "Percorso di viaggio",
        "measurements": "Misure",
        "notifications": "Notificazione",
        "positionTitle": "Informazioni sulla posizione",
        "settings": "Impostazione",
        "showOnMap": "Vedere su Google Maps",
        "showScheme": "Mostra schema",
        "signalQuality": "Qualità del segnale",
        "tracking": "Tracking"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}

.list-item__action {
    cursor: pointer;
}

.last_gps_measurement-icon {
    background-color: orange;
}

.item-info {
    margin-top: 3px;
    color: #808080;
}
</style>
