var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.state)?_c('measurement-item',{attrs:{"title":_vm.state.title,"datetime":_vm.state.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: 'd1',
                labelPair: 'operation',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.state.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"no-padding-left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.state.value ? 'running' : 'notRunning'))+" ")])]},proxy:true}],null,false,1792578258)}):_vm._e(),(_vm.speed)?_c('measurement-item',{attrs:{"title":_vm.speed.title,"datetime":_vm.speed.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.speed.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SpeedIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('speed'))+": "),_c('h3',{staticClass:"sensor-data-speed"},[_vm._v(" "+_vm._s(_vm.speed.value)+" ")])]},proxy:true}],null,false,677427088)}):_vm._e(),_c('battery-measurements-fragment',{attrs:{"tracker":_vm.tracker}}),(_vm.hasRunningTimeAccess && _vm.runningTime)?_c('measurement-item',{attrs:{"title":_vm.runningTime.title,"datetime":_vm.runningTime.lastUpdated,"link-to":{
            name: 'runningTimeChart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('stopwatch-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('runningTime'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.runningTime.textDuration)+" ")])]},proxy:true}],null,false,2717431076)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }