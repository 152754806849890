<template>
    <div>
        <measurement-item
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: battery.key },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="battery.value"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="batteryStatus"
            :title="batteryStatus.title"
            :datetime="batteryStatus.lastUpdated"
            :link-to="
                batteryVoltage
                    ? {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: batteryVoltage.key,
                          },
                      }
                    : null
            "
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-status="batteryStatus.value"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.battery') }}:

                <h3>
                    {{
                        $t(
                            'shared.measurementOptions.battery_status.' +
                                batteryStatus.value
                        )
                    }}

                    <template v-if="batteryVoltage">
                        ({{ batteryVoltage.value }} V)
                    </template>
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-else-if="!battery && batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: batteryVoltage.key },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="100"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.battery_voltage') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import BatteryIcon from './icons/BatteryIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'BatteryMeasurementsFragment',
    components: {
        BatteryIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        battery() {
            return this.getMeasurementItem('battery')
        },
        batteryStatus() {
            return this.getMeasurementItem('battery_status')
        },
        batteryVoltage() {
            return this.getMeasurementItem('battery_voltage')
        },
    },
}
</script>
